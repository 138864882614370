#msg {
  position: fixed;
  z-index: 9999999999;
  opacity: 0;
  width: 400px;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
  overflow: hidden; }
  #msg i {
    font-size: 20px;
    vertical-align: middle;
    width: 1em;
    text-align: center; }
  #msg span {
    font-size: 15px; }
  #msg .msg-progress {
    position: absolute;
    margin: 0;
    padding: 0;
    border-width: 2px 0;
    bottom: 0;
    left: 0;
    width: 0;
    border-radius: 0; }
  #msg.showed {
    top: 50px;
    opacity: 1; }
  #msg.alert-no-icon i {
    display: none; }
  @media (min-width: 1200px) {
    #msg {
      width: 600px; } }
  @media (max-width: 479px) {
    #msg {
      width: 300px; } }
