body.site-navbar-small {
    padding-top: 4.8rem;
}

/* Old template Styles Start */
.site-navbar-small .site-navbar {
    height: 64px;
    font-weight: 500;
}

/* Column width Hack for browser compatibility start */
table.dataTable thead th,
table.dataTable thead td {
    padding: 10px 0px;
}

table.dataTable.compact thead th,
table.dataTable.compact thead td {
    padding: 10px 0px;
}
/* Column width Hack for browser compatibility end */

/*table.table-striped tbody tr:nth-child(4n), .table-striped tbody tr:nth-child(4n-1) {
    !*background: rgba(243,247,249,1);*!
    background-color: #F4F7F8;
}
table.table-striped tbody tr:nth-child(4n-2), .table-striped tbody tr:nth-child(4n-3) {
    !*background: rgba(255,255,255, 1);*!
    background-color: #fff;
}*/

table.table-striped tbody tr:nth-child(odd) {
    background-color: #F4F7F8;
}

table.dataTable tbody tr.selected,
table.dataTable tbody th.selected,
table.dataTable tbody td.selected,
table.dataTable tbody tr.selected a.btn {
    color: white;
    /*background-color: #D4EDEC;*/
    /*font-weight: bold;*/
}

table.dataTable tbody tr.selected a, table.dataTable tbody th.selected a, table.dataTable tbody td.selected a {
    color: #3e8ef7;
}

.site-menu-item.active>a{
    /*border: solid 1px rgba(11,105,227,1);*/
    color: rgba(11,105,227,1);
}

.page {
    background: #F4F7F8;
}

.page-content > .row:first-child {
    min-height: 85vh;
}

#ajaxPage {
    position: relative;
    min-height: calc(100% - 44px);
    background: #f1f4f5;
}

.navbar-inverse {
    background-color: #163844 !important;
}

.navbar-inverse .navbar-toolbar .nav-link:focus, .navbar-inverse .navbar-toolbar .nav-link:hover {
    background-color: #19424F !important;
}

.navbar-inverse .navbar-toolbar .nav-link.active,
.navbar-inverse .navbar-toolbar .nav-link.active:focus,
.navbar-inverse .navbar-toolbar .nav-link.active:hover,
.navbar-inverse .navbar-toolbar > .active > .nav-link,
.navbar-inverse .navbar-toolbar > .active > .nav-link:focus,
.navbar-inverse .navbar-toolbar > .active > .nav-link:hover {
    color: #fff;
    background-color: #19424F !important;
    border-bottom: 4px solid #2AA5A1;
}

/*.navbar-inverse .navbar-toolbar .nav-link {
    padding-top: unset !important;
    padding-bottom: unset !important;
}*/

.navbar-inverse .navbar-toolbar .nav-link .icon {
    font-size: 18px;
    margin-right: 5px;
    /*line-height: 56px;*/
}

.navbar-inverse .navbar-toolbar .nav-link span {
    /*line-height: 50px;*/
}

.site-menubar-light .site-menu-item > a {
    line-height: 44px;
}

.site-menubar-light .site-menu-item > a:hover {
    color: #fff;
    background-color: #245E71 !important;
}

.site-menubar.site-menubar-light {
    background: #19424F !important;
    color: rgba(118,131,143,.9);
    color: white;
    font-weight: 500;
    height: unset;
}

.site-menubar-light .site-menu-item.active > a {
    color: white;
    background-color: rgba(11,105,227,.03);
    background-color: #245E71 !important;
    border-bottom: 4px solid #2AA5A1;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #245E71 !important;
}
/* Old template Styles End */


/* Bootstrap overwrites Start */
.flex-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
}

.btn {
    /*padding: 0.286rem 1.54rem;*/
    font-weight: bold;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #2AA5A1;
    border-color: #2AA5A1;
}

.input-group-text {
    background-color: #E9EFF1;
    border: 1px solid #E9EFF1;
}

/* Bootstrap overwrites End */

.badge-default-cs {
    background-color: #E9EFF1;
    color: #454B54
}

.badge-success-cs {
    color: #FFFFFF;
    background-color: #92C24F;
}

.badge-primary-cs {
    color: #FFFFFF;
    background-color: #2AA5A1;
}

.btn-success-cs {
    font-weight: bold;
    color: #fff;
    background-color: #92C24F;
}

.btn-primary-cs,
.btn-primary-cs:hover {
    font-weight: bold;
    color: #fff;
    background-color: #2AA5A1;
}

.btn-primary-outline-cs,
.btn-primary-outline-cs:hover {
    background-color: #fff;
    border-color: #2AA5A1;
    color: #2AA5A1;
}

.table.table-borderless td,
.table.table-borderless th {
    border: none;
}

.cs-nav-tabs {
    width: 100%;
}

.cs-nav-tabs .nav-tabs .nav-item.show .nav-link,
.cs-nav-tabs .nav-tabs .nav-link.active {
    font-weight: bold;
    background-color: #E9F6F5 !important;
}

.cs-nav-tabs .nav-tabs-line .nav-link {
    color: #454B54;
    font-weight: bold;
    padding: 12px 6px 13px 6px;
    border: unset;
}

.cs-nav-tabs .nav-tabs-line .nav-link:hover {
    border-bottom: 4px solid #2AA5A1;
}

.cs-nav-tabs .nav-tabs-line .nav-link.active {
    color: #228481;
    background-color: transparent;
    border-bottom: 4px solid #2AA5A1;
}

/*.cs-nav-tabs .dataTables_scrollHead {
    visibility: hidden;
}*/

.cs-nav-tabs .dataTables_scrollHead thead th {
    background: #fff;
}

.cs-nav-tabs.visible-table-headers .dataTables_scrollHead {
    visibility: visible;
}

.cs-tab-dataTable tr {
    margin-bottom: 20px;
}

.cs-tab-dataTable td,
.cs-tab-dataTable td th {
    border-top: unset;
}

.cs-tab-dataTable {
    background-color: #F4F7F8;
    border-collapse: separate;
    border-spacing: 16px;
}

.cs-tab-dataTable tr {
    background-color: #FFFFFF;
    /*cursor: pointer;*/
}

.cs-tab-dataTable tr:hover {
    background-color: #fff !important;
}

.cs-border {
    border: 1px solid #E7E8E9;
}

.cs-border-top {
    border-top: 1px solid #E7E8E9;
}

.cs-border-bottom {
    border-bottom: 1px solid #E7E8E9;
}

.cs-border-left {
    border-left: 1px solid #E7E8E9;
}

.cs-border-right {
    border-right: 1px solid #E7E8E9;
}

.cs-btn-reply-mngr {
    padding:  1px 10px;
    margin: 0 5px;
}

.cs-btn-reply-mngr img {
    height: 26px;
}

.cs-btn-ebay-return {
    padding: 5px 20px;
    margin: 0 5px;
}

.cs-btn-ebay-return img {
    height: 16px;
}

.cs-btn-ebay-account {
    padding: 5px 20px;
    margin: 0 5px;
}

.cs-btn-ebay-account img {
    height: 16px;
}

table.cs-table {
    color: #000000;
    font-weight: 400;
}

table.cs-table thead {
    background-color: #E9EFF1;
}

table.cs-table thead th {
    font-size: 12px;
    font-weight: bold;
}

table.cs-table tr.even {
    background-color: #F4F7F8;
}

/*table.cs-table tr:nth-child(even) {
    background-color: #F4F7F8 !important;
}*/

.cs-fixed-list {
    max-height: 270px;
    min-height: 270px;
    overflow: auto;
}

table.cs-table-list {
    margin-bottom: 0;
}

table.cs-table-list tr:nth-child(odd) {
    background-color: #F4F7F8 !important;
}

table.cs-table-list td,
table.cs-table-list th {
    padding: 6px 20px;
    font-size: 12px;
    color: #5B6169;
}

table.cs-table-list tr td:nth-child(2) {
    color: #2D343E;
    font-weight: 400;
}

table.cs-table-sm tr td:nth-child(3) {
    padding-right: 2px;
}
table.cs-table-sm tr td:nth-child(2) {
    padding-left: 0;
    padding-right: 0;
}
table.cs-table-sm tr td:nth-child(1) {
    padding-left: 3px;
    padding-right: 3px;
}

/* Disables the background for scroller datatables*/
div.DTS div.dataTables_scrollBody {
    background: unset;
}

#cs-send-note-form {
    position: relative;
}

.cs-btn {
    border: 1px solid #C5CDDE;
    margin-right: 5px;
}

.cs-btn:hover,
.cs-btn:active,
.cs-btn:focus {
    background-color: #C5CDDE;
    border-color: unset !important;
    color: unset !important;
}

/*#cs-table-nav-tabs .nav-link {
    padding: 5px 10px;
}*/

/* Scrollbars start */
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #E9EFF1; /*#f1f1f1*/
    width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #D3DFE3; /*#D9D9D9*/
    width: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #2AA5A1; /*#8b8b8b*/
    width: 8px;
}
/* Scrollbars end */

/*table.cs-datatable {
    border: 1px solid #e4eaec;
}*/

table.cs-datatable tr {
    border-bottom: 1px solid #e4eaec;
    background-color: #F4F7F8 !important;
    font-size: 12px;
    cursor: pointer;
}

table.cs-datatable tr.selected {
    background-color: #D4EDEC !important;
    color: #161E29 !important;
    font-weight: bold;
}

table.cs-datatable tr.selected a {
    color: #3e8ef7 !important;
}

table.cs-datatable tr td {
    outline: none;
    white-space: unset;
    padding: 0.250rem;
}

/* Panels Start */
.cs-tickets-panel table.cs-datatable tr {
    color: #454B54;
    font-size: 12px;
}

.cs-tickets-panel {
    padding: 0;
}

.cs-main-col .dataTables_scrollBody {
    border: 1px solid #e4eaec !important;
}

/*.panel {
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 4px 7px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 4px 7px 1px rgba(0, 0, 0, 0.1);
}*/

.panel {
    box-shadow: 0 1px 12px 0 rgba(22,30,41,0.16);
    width: 100%;
    margin-bottom: unset;
}

.panel-title {
    font-size: 13px;
}

.panel-bordered>.panel-heading {
    border-bottom: 1px solid #E7E8E9;
}

/* Panels End */

.cs-background-color {
    background-color: #F4F7F8;
}

.cs-text-dark {
    color: #2D343E;
    font-weight: 400;
}

tr.selected .cs-text-dark {
    color: #ffffff
}

.cs-collapse,
.cs-accordion-collapse {
    cursor: pointer;
    display: inline;
}

.cs-collapse.collapsed span.site-menu-arrow,
.cs-accordion-collapse.collapsed span.site-menu-arrow{
    transform: rotate(-90deg);
}

.cs-accordion-collapse .card-header {
    background-color: unset;
}

.cs-username-text {
    color: #EB6506 !important;
}

.cs-title-badge {
    position: absolute;
    left: 22px;
    top: 13px;
    padding: 7px;
}

.cs-btn-send-note {
    position: absolute;
    right: 0;
    top: 0;
}

.webui-popover-content {
    font-size: 10px;
    /*font-weight: bold;*/
    color: #000000;
}

.cs-accordion .card {
    margin-bottom: unset;
}

.cs-reason {
    color: #2D343E;
    font-style: italic;
    height: 56px;
    padding: 16px !important;
    overflow: auto;
}

.cs-imei .badge {
    background-color: #92C24F !important;
}

.cs-modal-alert {
    position: absolute;
    z-index: 1;
    width: 100%;
}

/*table.dataTable th.focus,
table.dataTable td.focus {
    outline: none;
}*/

table.dataTable tbody th.focus, table.dataTable tbody td.focus {
    box-shadow: unset;
}

/* Clear button styles
--------------------------------------------- */
::-ms-clear {
    display: none;
}

.form-control-clear {
    z-index: 10;
    pointer-events: auto;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 5px;
}

.select2-dropdown {
    z-index: 1800 !important;
}

.select2-results__option .wrap:before{
    font-family:fontAwesome;
    color:#999;
    content:"\f096";
    width:25px;
    height:25px;
    padding-right: 10px;

}
.select2-results__option[aria-selected=true] .wrap:before{
    content:"\f14a";
}

.JS-tbl-to-select2 {
    cursor: pointer;
}

/* Loader Start */
#loading {
    background-color: #163844;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999999;
    margin-top: 0px;
    top: 64px;
}

#loading-center {
    width: 100%;
    height: 100%;
    position: relative;
}

#loading-center-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 150px;
    width: 150px;
    margin-top: -75px;
    margin-left: -75px;
}

.object {
    width: 20px;
    height: 20px;
    background-color: #FFF;
    float: left;
    margin-right: 20px;
    margin-top: 65px;
    -moz-border-radius: 50% 50% 50% 50%;
    -webkit-border-radius: 50% 50% 50% 50%;
    border-radius: 50% 50% 50% 50%;
}

#object_one {
    -webkit-animation: object_one 1.5s infinite;
    animation: object_one 1.5s infinite;
}

#object_two {
    -webkit-animation: object_two 1.5s infinite;
    animation: object_two 1.5s infinite;
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
}

#object_three {
    -webkit-animation: object_three 1.5s infinite;
    animation: object_three 1.5s infinite;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

@-webkit-keyframes object_one {
    75% {
        -webkit-transform: scale(0);
    }
}

@keyframes object_one {
    75% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
}

@-webkit-keyframes object_two {
    75% {
        -webkit-transform: scale(0);
    }
}

@keyframes object_two {
    75% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
}

@-webkit-keyframes object_three {
    75% {
        -webkit-transform: scale(0);
    }
}

@keyframes object_three {
    75% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
}
/* Loader End */
